<template>
    <div class="top-level">
        <div class="text-h4">Who am I?</div>
        <div class="text-h6">Personality</div>
        <p>
            Broad experience in project design and development in different languages and using different 
            technologies, mostly web based. My favored programming languages are JavaScript, Python, and 
            other scripting languages in general. Within my projects, automated tests and documentation are 
            key elements. Over time, front-end development has become a passion and a second nature to me, 
            where the customer satisfaction is a top-priority.
        </p>

        <div class="text-h6">Technical skills</div>
        <p>
            <table>
                <tr>
                    <th>Back-end</th>
                    <td>Python, Perl, Groovy and Grails, PHP, Bash</td>
                </tr>
                <tr>
                    <th>Front-end</th>
                    <td>Javascript (Vue.JS, React, Ajax, jQuery, D3), ES6, Babel, WebPack, HTML, CSS, Bootstrap,  XML/XSLT, SVG</td>
                </tr>
                <tr>
                    <th>Environments</th>
                    <td>Linux (Ubuntu, Fedora), MS Windows, VMWare</td>
                </tr>
                <tr>
                    <th>Databases</th>
                    <td>SQL, Oracle, MySQL, Google Firebase, NoSQL databases</td>
                </tr>
                <tr>
                    <th>Development tools</th>
                    <td>VSCode, NodeJS, npm, Yarn, Docker, Electron, Git, GitLab, Geb, Spock, Selenium/WebDriver, MVC, MVVC</td>
                </tr>
                <tr>
                    <th>Softwares</th>
                    <td>Apache Web server, Dokuwiki and other CMS/wikis, TeamQuest (performance management s/w), HP-OVPA</td>
                </tr>
                <tr>
                    <th>Good practices</th>
                    <td>Scrum, Kanban, design patterns, test driven development (TDD)</td>
                </tr>
            </table>
        </p>

        <div class="text-h6">Soft skills</div>
        <p>
            <strong>People management:</strong>
            <ul>
                <li>Front-end development coaching</li>
                <li>Development team coaching at Keyrus through Scrum</li>
                <li>Operations Center management at Mastercard, European headquarters</li>
                <li>Non professional : unit leader in a youth movement, managing 20+ leaders and responsible for 100+ children.</li>
            </ul>
            </p>
            <p>
                <strong>Office tools:</strong><br />
                MS Office suite, LibreOffice suite, image optimization, mind mapping
            </p>
            <p>
                <span class="en-evidence">Team player</span> with a positive attitude that others trust and enjoy working with 
                ♦ Agile mindset (such as Scrum) 
                ♦ <span class="en-evidence">Autonomous</span> 
                ♦ <span class="en-evidence">Good analytical</span> and synthesis skills 
                ♦ Interested to work in a dynamic and open to <span class="en-evidence">multi-cultural environment</span> 
                ♦ <span class="en-evidence">Organizer</span> 
                ♦ Ability to write <span class="en-evidence">clear technical documents</span> and present complex topics 
                ♦ Ability to <span class="en-evidence">understand business requirements</span> and translate them into technical requirements 
                ♦ Curious about the <span class="en-evidence">high-level vision</span> of a project 
                ♦ Find <span class="en-evidence">creative</span> ways to solve problems
            </p>
        </p>

        <div class="text-h6">Languages</div>
        <p>
            <table>
                <tr><th>French</th> <td>mother language</td></tr>
                <tr><th>English</th> <td>fluent, read, written and spoken</td></tr>
                <tr><th>Dutch</th><td>good basis, lack of practice</td></tr>
            </table>
        </p>

        <div class="text-h6">Education</div>
        <p>
            School and diploma: Bachelor Analyst-programmer in 1986, at EPHEC, Brussels.
        </p>

        <div class="text-h6">Other training:</div>
        <p>
            <table>
                <tr> <th>Training name</th>             <th>Organizing company</th> <th>Duration, year</th> </tr>
                <tr> <th>Angular</th>                   <td>OpenClassrooms</td>     <td>Self-paced, 2020</td> </tr>
                <tr> <th>AngularJS</th>                 <td>OpenClassrooms</td>     <td>Self-paced, 2020</td> </tr>
                <tr> <th>React-Native</th>              <td>OpenClassrooms</td>     <td>Self-paced, 2020</td> </tr>
                <tr> <th>PHP Symfony</th>               <td>OpenClassrooms</td>     <td>Self-paced, 2019</td> </tr>
                <tr> <th>SASS/SCSS</th>                 <td>OpenClassrooms</td>     <td>Self-paced, 2019</td> </tr>
                <tr> <th>Adobe Lightroom</th>           <td>CEFORA</td>             <td>2 days, 2015</td> </tr>
                <tr> <th>Scrum methodology</th>         <td>CEFORA/Pixis</td>       <td>2 days, 2015</td> </tr>
                <tr> <th>ORACLE DBA</th>                <td>Oracle Belgium</td>     <td>5 days, 2010</td> </tr>
                <tr> <th>ORACLE PL/SQL</th>             <td>Oracle Belgium</td>     <td>5 days, 2010</td> </tr>
                <tr> <th>HP Operations</th>             <td>Hewlett-Packard</td>    <td>5 days, 2004</td> </tr>
                <tr> <th>HP Network Node Manager</th>   <td>Hewlett-Packard</td>    <td>5 days, 2004</td> </tr>
                <tr> <th>SAS ITSV</th>                  <td>SAS Institute</td>      <td>4 days, 2000</td> </tr>
                <tr> <th>SAS Base</th>                  <td>SAS Institute</td>      <td>3 days, 2000</td> </tr>
            </table>
        </p>

        <div class="text-h6">Career and main projects</div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Senior Front-end Developer</div>
                <div class="career-item-headline-date">05-2019 – 04-2020</div>
                <div class="career-item-headline-client">Tessenderlo Group (Devoteam)</div>
            </div>
            <div class="career-details">
                <strong>Skills</strong>
                <p>
                    JavaScript, React/Redux, CSS, HTML, git, gitlab, Confluence, Jira, SharePoint, React+Redux, Confluence, Jira
                </p>
                <strong>Job description: </strong>
                    <ul>
                        <li>Design, implement  and deploy reusable web components (React+Redux)</li>
                        <li>Design,implement and deploy corporate websites in SharePoint</li>
                        <li>Maintain existing websites in SharePoint</li>
                        <li>Coach a junior</li>
                    </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Front-end developer</div>
                <div class="career-item-headline-date">09-2016 – 04-2019</div>
                <div class="career-item-headline-client">European Commission DG JRC (Cronos)</div>
            </div>
            <div class="career-details">
                <strong>Skills:</strong>
                <p>
                    JavaScript, Vue.js, D3.js, Sigma.js, Leaflet.js, CSS, HTML, NodeJS, ES6, Babel, WebPack, Electron, Selenium/WebDriver, git, python, Jupyter
                </p>
                <strong>Job description: </strong>
                    <ul>
                        <li>Produce data visualisations through innovative dynamic charts (Vue.js, D3, leaflet, SVG)</li>
                        <li>Produce various desktop tools to ease code versionning and management (NodeJS, Electron)</li>
                        <li>Design, implement and deploy reusable web components and API (Vue.js)</li>
                        <li>Produce a desktop tool to ease end-to-end testing (Python, Selenium/WebDriver)</li>
                        <li>Produce tests and documentation</li>
                    </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Perl expert</div>
                <div class="career-item-headline-date">07-2015 – 07-2016</div>
                <div class="career-item-headline-client">European Parliament DG EPRS (Cronos)</div>
            </div>
            <div class="career-details">
                <strong>Skills:</strong>
                <p>
                    Perl, JavaScript, CSS, HTML, SirsiDynix Symphony (library software management)
                </p>
                <strong>Job description:</strong>
                    <ul>
                        <li>Produce perl scripts to clean-up and transpose the books databases</li>
                        <li>Produce web tools and web apps to ease in library daily operations (input validations, cataloguer approval, acurate label printing, ...)</li>
                    </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Career break, job seeking</div>
                <div class="career-item-headline-date">09-2014 – 07-2015</div>
                <div class="career-item-headline-client"></div>
            </div>
            <div class="career-details">
                <ul>
                    <li>Benevolent as MS-Office teacher and website coach for non-profit organizations.</li>
                    <li>As website coach, I installed and configured company websites (WordPress), designed logos, and train non-profit organization people to be able to maintain their own web site without assistance.</li>
                    <li>I also gave private JavaScript classes.</li>
                </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Lead Software Engineer</div>
                <div class="career-item-headline-date">03-2006 – 09-2014</div>
                <div class="career-item-headline-client">CREATIVE Associates</div>
            </div>
            <div class="career-details">
                <strong>Skills:</strong> Perl, JavaScript, jQuery, KineticJS, CSS, HTML, Groovy, Grails, Java, SQL, ORACLE DB daily ops, Linux, Solaris, AIX, VMWare, TeamQuest (system performance management and optimization), subversion, git, project management, project architecture, Test driven development (TDD), Geb, Spock, Selenium
                <br />
                <strong>Job description: </strong>
                    <ul>
                        <li>Design, implement and deploy a system performance monotoring tool (perl + HTML + CSS + JavaScript). The product has been sold to worldwide major accounts and generated about 30% of the company profits. After five years of existence, the initial product has been re-written in Groovy/Grails/Tomcat.</li>
                        <li>Lab maintenance and daily operations (DBA on ORACLE, system engineer on Linux, MS Windows, Solaris, AIX and VMWare vSphere).</li>
                        <li>Various consultance missions (performance audits, performance management, Rebilling engine based on performance metrics, ...)</li>
                    </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Senior IT consultant</div>
                <div class="career-item-headline-date">01-2000 – 02-2006</div>
                <div class="career-item-headline-client">Keyrus</div>
            </div>
            <div class="career-details">
                <strong>Skills:</strong>
                <p>
                    Perl, JavaScript, CSS, HTML, SAS BASE &amp; ITSV, SQL, PL/SQL, web design, Crystal Reports
                </p>
                <strong>Job description: </strong>
                    <ul>
                        <li>Various projects around system performance management (BASE, Dexia, KPN/Orange, Proximus, ASTRID, KBLux, Bank Card Company, Atos Worldline)</li>
                        <li>System performance audits (BASE, KB Swiss, Police de Bruxelles, Christelijke Mutualiteit)</li>
                        <li>Various missions around HP Openview products (SES Astra, DHL, BASE, KPN/Orange)</li>
                        <li>Architecture, design, API design, implementation and management of a clearing system for the VREG (Vlaams regulator van de electriciteits-en gasmarkt), including data interchange design between electricity or gas transporters, providers and producers.</li>
                        <li>Implementation of a full performance reporting tool (KPN-Orange/BASE), through an innovative web interface.</li>
                    </ul>
            </div>
        </div>
        <div class="career-item">
            <div class="career-item-headline">
                <div class="career-item-headline-title">Miscellaneous positions</div>
                <div class="career-item-headline-date">12-1986 – 12-1999</div>
                <div class="career-item-headline-client">Eurocard/Mastercard</div>
            </div>
            <div class="career-details">
                <p>
                    During 14 years spent within Mastercard, I fullfilled various functions, from <em>Operator</em> at the beginning up to <em>MIS Engineer</em> at the end.
                </p>
            </div>
        </div>

        <div class="text-h6">Hobbies and interests</div>
        <p>
            Playing music with friends, photography, board games.
        </p>

    </div>
</template>

<script>
export default ({
    name: 'Resume',
    data() {
        return {};
    },
})
</script>

<style scoped lang="less">
.top-level {
    padding: 1em;
}
.text-h6 {
    border-bottom: 4px solid #07f;
}
.en-evidence {
    color: #07f;
    font-weight:bold;
}

th {
    text-align: left;
    padding-right: 1em;
}
p {
    margin-left:2em;
}
.career-item{
    margin-left: 1em;
}
.career-item-headline{
    font-size: 1.2em;
    border-bottom: 2px solid #07f;
}
.career-item-headline-title{
    display: inline-block;
    width: 15em;
    font-weight: bold;

}
.career-item-headline-date{
    display: inline-block;
    width: 10em;
}
.career-item-headline-client{
    display: inline-block;
    width: 20em;
}
.career-details {
    margin-left: 2em;
}
</style>
